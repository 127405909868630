import { faAngleDown, faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'

const MenuWithSub = (props) => {

  return (
    <div className="dropdown-wrap">
      <div className="submenu-wrap">
        <Link to={props.link} className="has-sub">
          {" "}
          {props.name}{" "}
          <FontAwesomeIcon icon={faAngleDown} className="sub-icon" />{" "}
        </Link>

        <div
          className="sub-wrap"
        >
          {props.children}
        </div>
      </div>
    </div>
  );
}

const SubMenu = (props) => {

  return (
    <div className="submenu-item-wrap">
      <div className="submenu">
        <div className="submenu-title">
          <p>
            {props.title} <FontAwesomeIcon icon={faChevronRight} />{" "}
          </p>
          <div className="submenu-items">{props.children}</div>
        </div>
      </div>
    </div>
  );
}

export default MenuWithSub
export {SubMenu}
