import React from 'react'
import { Col, FormControl, FormLabel, Row } from 'react-bootstrap';
import InnerBanner from '../innerBanner';
import Loader from '../layout/loader';
import PageHeading from '../pageHeading';

const Investor = () => {
  return (
    <>
    <Loader />
        <InnerBanner title="INVESTOR" />
        <PageHeading heading="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />

        <div className="investor-form">
            <div className="form-wrap">
                <form action="">
                    <Row className='g-4'>
                        <Col md="6">
                            <FormLabel>First Name</FormLabel>
                            <FormControl placeholder='John' />
                        </Col>

                        <Col md="6">
                            <FormLabel>Last Name</FormLabel>
                            <FormControl placeholder='Doe' />
                        </Col>

                        <Col md="6">
                            <FormLabel>Email Address</FormLabel>
                            <FormControl type='email' placeholder='johndoe@email.com' />
                        </Col>

                        <Col md="6">
                            <FormLabel>Phone number</FormLabel>
                            <FormControl type='number' placeholder='000 000 0000' />
                        </Col>

                        <Col md="12">
                            <FormLabel>Subject</FormLabel>
                            <FormControl placeholder='Enter your subject for the inquiry' />
                        </Col>

                        <Col md='12'>
                            <FormLabel>Message</FormLabel>
                            <FormControl as='textarea' placeholder='Type you message here' rows={6} />
                        </Col>

                        <Col md='12'>
                            <button type="submit" className='btn submit-btn'>Send Message</button>
                        </Col>
                    </Row>
                </form>
            </div>
        </div>
    </>
  )
}

export default Investor;