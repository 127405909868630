import React from 'react'
import info from '../../assets/img/info-shape.png'

const Info = () => {
  return (
    <section className="info-section">
      <img src={info} alt="" className="shape" />

      <div className="info-content d-flex justify-content-end">
        <div
          data-aos-duration="500"
          data-aos="fade-right"
          className="w-75 d-flex flex-column"
        >
          <p className="info-text text-end w-100">
            “Think Big, Plan Big, Execute Big” <br />
          </p>

          <div>
            <p className="info-text text-end"> - Jay Patel</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Info
