import React from "react";
import InnerBanner from "../innerBanner";
import PageHeading from "../pageHeading";
import img from "../../assets/img/flashplant.png";
import { Col, Row } from "react-bootstrap";
import DetailBottomBanner from "./DetailBottomBanner";
import TechnologyBox from "../technologyBox";
import Loader from "../layout/loader";
import Slider from "react-slick";

const FlashPlant = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
  };
  
  return (
    <>
      <Loader />
      <InnerBanner title="Our Technology" />
      <PageHeading
        heading="In a flash steam power plant, geothermal-heated water under pressure is estranged in a surface vessel, which is called a steam separator. The steam is delivered to the turbine and then a generator is used to generate electricity. The liquid is injected back into the reservoir."
        align="text-start"
      />

      <div className="detail-info-wrap">
        <Row className="gy-5 justify-content-between">
          <Col xxl="5">
            <div
              data-aos-duration="700"
              data-aos="fade-up"
              className="overflow-hidden"
            >
              <img
                src={img}
                alt="ORGANIC RANKIN CYCLE (ORC) PLANT"
                className="plant-info-img"
              />
            </div>
          </Col>

          <Col xxl="6">
            <div
              data-aos-duration="700"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <p className="detail-info-text">
                There are locations where we can find geothermally heated water
                reservoirs at a high temperature of over 350 Fahrenheit. In such
                locations, flash steam power plants develop. This hot water
                reservoir is known as brine. This brine is transfer to a vessel
                where it cools. As it cools, the fluid quickly turns into vapor
                (flash) and the waste brine is injected back into the earth.
                These vessel-generated vapors drive the turbine, and the rest of
                the procedure is the same as in dry steam power plants.
              </p>
            </div>
          </Col>

          <Col>
            <div data-aos-duration="700" data-aos="fade-up">
              <p className="detail-info-text">
                The most common type of power plant to date is a flash power
                plant, where a mixture of liquid water and steam is produced
                from the wells. At a flash facility, hot liquid water from deep
                in the earth is under pressure and thus kept from boiling. As
                this hot water moves from deeper in the earth to shallower
                levels, it quickly loses pressure, boils and ‘flashes’ to steam.
                The steam is separated from the liquid in a surface vessel
                (steam separator) and is used to turn the turbine, and the
                turbine powers a generator. Flash power plants typically require
                resource temperatures in the range of 350–500°F (177° to 260°C).
                A number of technology options can be used with a flash system.
              </p>
            </div>
          </Col>
        </Row>
      </div>

      {/* <div className="detail-bottom-banner">
        <Slider {...settings}>
          <TechnologyBox
            link="/technology/scicat-technology"
            title="BINARY CYCLE PLANT"
            desc="Binary cycle power plants transfer the heat from geothermal hot water to another liquid."
          />
          <TechnologyBox
            link="/technology/orc"
            title="ORGANIC RANKIN CYCLE (ORC) PLANT"
            desc="ORGANIC RANKIN CYCLE (ORC) PLANTs use steam directly from a geothermal reservoir to turn turbines"
          />
          <TechnologyBox
            link="/technology/rehm"
            title="REHM TECHNOLOGY"
            desc="REHM uses electro magnetic resonance (EMR) to selectively transfer energy for the dissociation of methane from hydrate. "
          />
          <TechnologyBox
            link="/technology/arcs"
            title="ARCS TECHNOLOGY"
            desc="Arcs provides a means to use solar energy captured by the oceans for emission-free power generation. "
          />
          <TechnologyBox
            link="/technology/swers"
            title="SWERS TECHNOLOGY"
            desc="SWERS provides the ability to save water and recover waste energy in thermal power plants."
          />
          <TechnologyBox
            link="/technology/flare-capture-and-monetization"
            title="Flare Recovery Technology"
            desc="Most efficient elimination and monetizing of onshore refinery, offshore platform, and land oil-well flaring."
          />
          <TechnologyBox
            link="/technology/natural-gas-liquids-recovery"
            title="NATURAL GAS LIQUIDS RECOVERY"
            desc="Most efficient method of Natural Gas Liquids recovery and hydrocarbon condensates recovery."
          />
          <TechnologyBox
            link="/technology/increased-pipeline-capacity"
            title="INCREASED PIPELINE CAPACITY"
            desc="Most efficient CAPEX and OPEX of oil and gas pipeline infrastructures from capacity optimization."
          />
          <TechnologyBox
            link="/technology/hydrogen"
            title="Hydrogen"
            desc="Hydrogen energy is a source of renewable energy, produced by electrolysis."
          />
        </Slider>
      </div> */}
    </>
  );
};

export default FlashPlant;
