import React from 'react'

const PortfolioBox = props => {
  return (
    <a href={props.link} target='_blank' className='portfolio-box d-flex align-items-center justify-content-center'>
      <img src={props.img} alt="" className="portfolio-img" />
    </a>
  )
}

export default PortfolioBox
