import React from 'react'
import InnerBanner from '../innerBanner'
import PageHeading from '../pageHeading'
import swers2 from '../../assets/img/increased.png'
import { Col, Row } from 'react-bootstrap'
import DetailBottomBanner from './DetailBottomBanner'
import TechnologyBox from '../technologyBox'
import Loader from '../layout/loader'
import Slider from 'react-slick'

const IncreasedPipelineCapacity = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1
  }

  return (
    <>
      <Loader />
      <InnerBanner title='Our TECHNOLOGY' />
      <PageHeading
        heading='INCREASED CAPACITIES AND REDUCED EMISSIONS BECOME POSSIBLE'
        align='text-start'
      />

      <div className='detail-info-wrap'>
        <Row className='gy-5 justify-content-between'>
          <Col xxl='5'>
            <div
              data-aos-duration='700'
              data-aos='fade-up'
              data-aos-delay='200'
            >
              <img
                src={swers2}
                alt='dry steam plant'
                className='plant-info-img'
              />
            </div>
          </Col>
          <Col xxl='7'>
            <div data-aos-duration='700' data-aos='fade-up'>
              <p className='detail-info-text'>
                Pipeline capacities can be increased and demonstrably GHG
                Methane Emissions decreased. This technology can flexibly
                convert all gas and oil productions to non-saturated
                non-slugging lowered pressure production flows. Carbon
                Footprints and Carbon Neutral Crude Oil production improvements
                can be achieved throughout the end-to-end lifecycle of
                production and transports, potentially achieving COP26 goals
                much earlier than stated in Annual Reports or to the Public.
              </p>
            </div>
          </Col>

          <Col xxl='12'>
            <div data-aos-duration='700' data-aos='fade-up'>
              <p className='detail-info-text'>
                The United States maintains about 2 million miles of natural gas
                distribution mains and pipelines, 321,000 miles of gas
                transmission and gathering pipelines, 175,000 miles hazardous
                liquid pipeline, and 114 active liquid natural gas plants that
                are connected to natural gas transmission and distribution
                systems. These large pipes are up to 42” diameter, with most
                being more than 10” diameter. Operating pressure is between
                1,500 psi to 3,000 psi, especially Offshore. Three flanges exist
                per mile on the average, along the transmission lines resulting
                in large cumulative continuous fugitive methane emissions to
                occur. Decreasing the need for high pressure flows required for
                saturated Oil and Gas flows, decreases fugitive methane leaks
                and offers improved equipment life and capacities.
              </p>
            </div>
          </Col>
        </Row>
      </div>

      {/* <div className='detail-bottom-banner'>
        <Slider {...settings}>
          <TechnologyBox
            link='/technology/scicat-technology'
            title='Technology'
            desc='Technology transfer the heat from geothermal hot water to another liquid.'
          />
          <TechnologyBox
            link='/technology/orc'
            title='ORGANIC RANKIN CYCLE (ORC) PLANT'
            desc='ORGANIC RANKIN CYCLE (ORC) PLANTs use steam directly from a geothermal reservoir to turn turbines'
          />
          <TechnologyBox
            link='/technology/rehm'
            title='REHM TECHNOLOGY'
            desc='REHM uses electro magnetic resonance (EMR) to selectively transfer energy for the dissociation of methane from hydrate. '
          />
          <TechnologyBox
            link='/technology/arcs'
            title='ARCS TECHNOLOGY'
            desc='Arcs provides a means to use solar energy captured by the oceans for emission-free power generation. '
          />
          <TechnologyBox
            link='/technology/swers'
            title='SWERS TECHNOLOGY'
            desc='SWERS provides the ability to save water and recover waste energy in thermal power plants.'
          />
          <TechnologyBox
            link='/technology/flare-capture-and-monetization'
            title='Flare Recovery Technology'
            desc='Most efficient elimination and monetizing of onshore refinery, offshore platform, and land oil-well flaring.'
          />
          <TechnologyBox
            link='/technology/natural-gas-liquids-recovery'
            title='NATURAL GAS LIQUIDS RECOVERY'
            desc='Most efficient method of Natural Gas Liquids recovery and hydrocarbon condensates recovery.'
          />
          <TechnologyBox
            link='/technology/hydrogen'
            title='Hydrogen'
            desc='Hydrogen energy is a source of renewable energy, produced by electrolysis.'
          />
        </Slider>
      </div> */}
    </>
  )
}

export default IncreasedPipelineCapacity
