import React from 'react'
import PageHeading from '../pageHeading';

const Advantages = () => {
  return (
    <>
      <div className={`page-heading-wrap`}>
        <div data-aos-duration="700" data-aos="fade-up" className="w-100">
          <h4 className={`page-heading`}>Competitive Advantage</h4>
        </div>
      </div>

      <div className="padding-x pb-5">
        <p className="py-5 fs-4">
          Our existing and prospective portfolio companies offer the following
          benefits:
        </p>

        <ul>
          <li className='mb-4'>
            <b>Reliability: </b>Our efforts are focused on solving the
            reliability problem. Geothermal offers the highest capacity of any
            renewable energy, expanding available energy sources creates
            redundancy, enhancing power storage solutions increases reliability
            in emergency, and making the entire system more efficient improves
            global availability.
          </li>

          <li className='mb-4'>
            <b>Flexibility: </b>Our support in creating a portfolio of
            innovations delivers optionality for various power scenarios while
            simultaneously improving our existing foundation and driving toward
            a brighter future.
          </li>

          <li className='mb-4'>
            <b>Energy Security: </b>We are moving the boundaries of our energy
            infrastructure across the full spectrum of solutions, we are
            delivering global energy security through development of new
            sources, improved efficiency and cleanliness of the existing
            sources, and implementing new renewable solutions globally.
          </li>

          <li className='mb-4'>
            <b>Economic Benefits: </b>We are ensuring more intelligent use of
            our existing resources while continuing to drive creation of new
            solutions to deliver global savings though efficiency and better
            resource allocation.
          </li>

          <li className='mb-4'>
            <b>Environmental Protection: </b>Every aspect of our portfolio is
            focused on improving our environment. Whether through the
            development of alternative energy solutions, bridge solutions to
            generate cleaner energy or the improvement of the markets and
            infrastructure to support a new, cleaner energy solution, everything
            we do is focused on preserving our environmental future.
          </li>

          <li className='mb-4'>
            <b>Scalability: </b>Every solution that ENERGY196 supports is
            designed to be scalable on global scale. Only through the
            development of global solutions can we truly deliver on the promises
            of energy security, environmental protection and carbon elimination.
          </li>
        </ul>
      </div>
    </>
  );
}

export default Advantages
