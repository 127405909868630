import React from "react";
import InnerBanner from "../innerBanner";
import solution1 from "../../assets/img/solution1.png";
import solution2 from "../../assets/img/solution2.png";
import solution3 from "../../assets/img/solution3.png";
import solution4 from "../../assets/img/solution4.png";
import solution5 from "../../assets/img/solution5.png";
import solution6 from "../../assets/img/solution6.png";

import Loader from "../layout/loader";
import solution from '../../assets/img/solution.svg'

const Solution = () => {
  return (
    <>
      <Loader />
      <InnerBanner
        title="SOLUTIONS FOR ALL STAKEHOLDERS
IN THE ENERGY TRANSITION"
      />
      <div className="container solution">
        <div data-aos-duration="700" data-aos="fade-up" className="container">
          <div className="row">
            <div className="col-md-12 text-center">
                <img src={solution} alt="solution" className="img img-responsive"/>
            </div>
          </div>
        </div>
      </div>

      <div className="container solutionImages">
        <div data-aos-duration="700" data-aos="fade-up" className="container">
          <div className="row">
            
          
            
            <div className="col-md-4">
              <div className="thumbnail">
                <img src={solution1} alt="Lights" />
                <div class="caption">
                  <h2>DISTRIBUTED ENERGY SYSTEMS</h2>
                  <p>Solutions for decentralised energy production and distribution, including smart grid, virtual power plants, flexibility, demand management, energy monitoring, optimisation, and decentralised asset ownership.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="thumbnail">
                <img src={solution2} alt="Lights" />
                <div class="caption">
                  <h2>DIGITAL UTILITIES</h2>
                  <p>Energy-related innovations such energy-as-a-service, energy communities, prosumers, digital transformation, peer-to-peer trading, energy audits, and energy data management.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="thumbnail">
                <img src={solution3} alt="Lights" />
                <div class="caption">
                  <h2>MOBILITY & TRANSPORT</h2>
                  <p>Electrification of cars and other forms of transportation, charging infrastructure and services, micromobility, intelligent traffic, intelligent logistics, and mobility-as-a-service.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="thumbnail">
                <img src={solution4} alt="Lights" />
                <div class="caption">
                  <h2>BUILT ENVIRONMENT</h2>
                  <p>Intelligent metres, heat networks, energy efficiency, intelligent lighting, building management, power distribution, and urban infrastructure.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="thumbnail">
                <img src={solution5} alt="Lights" />
                <div class="caption">
                  <h2>INDUSTRIAL ENERGY MANAGEMENT</h2>
                  <p>Industry 4.0, asset optimization, planned maintenance, electrification, energy conversion, energy procurement, and integration of the energy market are all examples of ways to improve industrial efficiency.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="thumbnail">
                <img src={solution6} alt="Lights" />
                <div class="caption">
                  <h2>AI & ENABLING TECHNOLOGIES</h2>
                  <p>Blockchain, distributed computing, cybersecurity, and data management are some of the technologies and uses of artificial intelligence.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

  <br/>  <br/>
    </>
  );
};

export default Solution;
