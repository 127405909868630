import React from 'react'
import InnerBanner from '../innerBanner'
import PageHeading from '../pageHeading'
import specific_arc from '../../assets/img/natural1.png'
import arcs1 from '../../assets/img/natural2.png'
import { Col, Row } from 'react-bootstrap'
import DetailBottomBanner from './DetailBottomBanner'
import TechnologyBox from '../technologyBox'
import Bottom from '../home/Bottom'
import PrimaryBgHeading from '../primaryBgHeading'

import Loader from '../layout/loader'
import Slider from 'react-slick'

const NaturalGasLiquidTechnology = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1
  }

  return (
    <>
      <Loader />
      <InnerBanner title='Our TECHNOLOGY' />
      <PageHeading
        heading='UNCONVENTIONAL TOWER-LESS GAS PROCESS'
        align='text-start'
      />

      <div className='detail-info-wrap'>
        <Row className='gy-5 justify-content-between'>
          <Col xxl='5'>
            <div
              data-aos-duration='700'
              data-aos='fade-up'
              data-aos-delay='200'
            >
              <img
                src={arcs1}
                alt='dry steam plant'
                className='plant-info-img'
                style={{ width: '100%' }}
              />
            </div>
          </Col>
          <Col xxl='7'>
            <div data-aos-duration='700' data-aos='fade-up'>
              <p className='detail-info-text'>
                Unconventional Tower-less Process for
                Condensate/NGL/HC-DewPointing with 99% C3+ Recovery with
                Specification-Ready stabilized product streams.
              </p>

              <p className='detail-info-text'>
                At almost ½ the CAPEX/OPEX of the almost 100% of the world's
                current 50 years old gas and cryogenics and tower based process
                technologies. The compactness and flexibility of this process
                makes previously untenable projects feasible in both offshore
                and onshore, starting from individual wellsites to greenfields,
                to retrofits, revamping existing Condensate/NGL facilities.
              </p>
            </div>
          </Col>
        </Row>
      </div>
      <PrimaryBgHeading
        heading='THE MOST ECONOMIC LNG PROCESS'
        className='pt-0 pb-0'
      />
      <div className='detail-info-wrap'>
        <Row className='gy-5 justify-content-between'>
          <Col xxl='6'>
            <div data-aos-duration='700' data-aos='fade-up'>
              <p className='detail-info-text'>
                This is the most economic of all LNG liquid processes for
                extracting heavier NGL components from "Rich" LNG cargoes. With
                no need for re-compression while delivering HHV Controlled,
                Gasification ready Liquid LNG or Pipeline Ready Gasification.
                With no need for Propane or Nitrogen Injections.
              </p>
            </div>
          </Col>

          <Col xxl='5'>
            <div
              data-aos-duration='700'
              data-aos='fade-up'
              data-aos-delay='200'
            >
              <img
                src={specific_arc}
                alt='dry steam plant'
                className='plant-info-img'
              />
            </div>
          </Col>
          <Col md='12'>
            <div data-aos-duration='700' data-aos='fade-up'>
              <p className='detail-info-text'>
                <ul>
                  <li>INCREASED GL REVENUES</li>
                  <li>REDUCED Cost of Oil or BOE Produced</li>
                  <li>REDUCED Cost of Gas Produced</li>
                  <li>
                    INCREASED Oil Throughputs - 2 to 3 times flow increase in
                    existing pipeline infrastructures.
                  </li>
                  <li>
                    INCREASED Gas Throughputs - 3 to 3.5 times flow increase in
                    existing pipeline infrastructures
                  </li>
                  <li>REDUCED Various Process Plant Facilities - CAPEX/OPEX</li>
                  <li>
                    REDUCED Pipeline Sizes, Class and Infrastructure costs -
                    CAPEX/OPEX
                  </li>
                  <li>
                    ENVIRONMENTAL IMPACTS Profound Decrease of Flaring, Venting
                    and Product GHG's
                  </li>
                  <li>
                    INCREASED Clean Fuels (e.g. NGL, LNG, CNG, GTL) closer to
                    source, minus Refined Product logistics.
                  </li>
                  <li>
                    PROVIDES EXTRACTION OF C2(VARIABLE)/NGL from LNG while
                    keeping LNG in Liquid Storage form.
                  </li>
                  <li>
                    CAN FINE-TUNE C2 EXTRACTION (close to 100% C2 Extraction)
                    for HHV control - No N2 Injection.
                  </li>
                  <li>
                    PROVIDES LNG GASIFICATION OPTION. - For better Economic for
                    either FSRU or Onshore Facility
                  </li>
                  <li>
                    PROVIDES BUYER/SELLER NGL Production/Trade
                    Economics/Revenues - margins and strategies.
                  </li>
                  <li>
                    OPENS UP BUYING of wide Range LNG (Heavy-Content)
                    Variable-Specs LNG Cargos.
                  </li>
                  <li>
                    OPENS UP FLEXIBILITIES/ECONOMICS of sourcing including
                    Stranded Field Strategies
                  </li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>
      </div>

      {/* <div className='detail-bottom-banner'>
        <Slider {...settings}>
          <TechnologyBox
            link='/technology/scicat-technology'
            title='Technology'
            desc='Technology transfer the heat from geothermal hot water to another liquid.'
          />
          <TechnologyBox
            link='/technology/orc'
            title='ORGANIC RANKIN CYCLE (ORC) PLANT'
            desc='ORGANIC RANKIN CYCLE (ORC) PLANTs use steam directly from a geothermal reservoir to turn turbines'
          />
          <TechnologyBox
            link='/technology/rehm'
            title='REHM TECHNOLOGY'
            desc='REHM uses electro magnetic resonance (EMR) to selectively transfer energy for the dissociation of methane from hydrate. '
          />
          <TechnologyBox
            link='/technology/arcs'
            title='ARCS TECHNOLOGY'
            desc='Arcs provides a means to use solar energy captured by the oceans for emission-free power generation. '
          />
          <TechnologyBox
            link='/technology/swers'
            title='SWERS TECHNOLOGY'
            desc='SWERS provides the ability to save water and recover waste energy in thermal power plants.'
          />
          <TechnologyBox
            link='/technology/flare-capture-and-monetization'
            title='Flare Recovery Technology'
            desc='Most efficient elimination and monetizing of onshore refinery, offshore platform, and land oil-well flaring.'
          />
          <TechnologyBox
            link='/technology/increased-pipeline-capacity'
            title='INCREASED PIPELINE CAPACITY'
            desc='Most efficient CAPEX and OPEX of oil and gas pipeline infrastructures from capacity optimization.'
          />
          <TechnologyBox
            link='/technology/hydrogen'
            title='Hydrogen'
            desc='Hydrogen energy is a source of renewable energy, produced by electrolysis.'
          />
        </Slider>
      </div> */}
    </>
  )
}

export default NaturalGasLiquidTechnology
