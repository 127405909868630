import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import InnerBanner from "../innerBanner";
import PageHeading from "../pageHeading";
import Team from "../team";
import jay from "../../assets/img/team/jayy.png";
import andy from "../../assets/img/team/manish.png";
import inside from "../../assets/img/inside.png";
// import Bottom from "../home/Bottom";
import Loader from "../layout/loader";
import Aos from "aos";

const AboutUs = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <Loader />
      <InnerBanner title="About Us" />
      <PageHeading heading="ENERGY196 is a diversified, middle market private equity firm aiming towards providing required energy and growth infrastructure solutions." />

      <div className="about-owner">
        <div className="about-heading-wrap">
          <div className="about-heading">
            <div data-aos-duration="500" data-aos="fade-up">
              <p className="text-center">
                Expertise and Integrity - Our FOUNDER and CEO
              </p>
            </div>
          </div>
        </div>

        <div className="owner-detail">
          <Row className="align-items-md-end justify-content-between gy-4">
            <Col lg="4">
              <div className="owner-img-wrap">
                <div data-aos-duration="700" data-aos="fade-right">
                  <Team img={jay} name="Dr. Jay Patel" position="CEO" />
                </div>
              </div>
            </Col>
            <Col lg="8">
              <div data-aos-duration="700" data-aos="fade-up">
                <p className="about-section-title">Dr. Jay Patel</p>
                <p className="about-section-desc">
                  Jay Patel, Doctor of Medicine and a Philanthropist has
                  supported many initiatives across various institutions and
                  geographies. Being visionary evangelist, Dr. Jay set foot into
                  entrepreneurship and so founded multiple companies to help
                  businesses reach their true potential. He is the founder and
                  CEO of EDGE196. He strongly believes that when one startup
                  becomes a unicorn, it can inspire and motivate a new
                  generation of entrepreneurs. Dr. Jay's commercial and business
                  acumen provides invaluable leadership to all his projects.
                </p>
              </div>
            </Col>
          </Row>
        </div>

        <div className="mission-wrap">
          <div className="mission-content">
            <div data-aos-duration="700" data-aos="fade-up">
              <p className="mission">
                Our mission at ENERGY196 is to drive progress and innovation in
                the energy industry through strategic investments in
                cutting-edge technologies and solutions. We are committed to
                advancing clean energy production, improving power storage
                capabilities, fostering the growth of renewable energy sources,
                and enabling sustainable mobility solutions. By investing in
                these areas, we aim to create a more efficient, sustainable, and
                secure energy future for generations to come.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="about-inner-section">
        <div data-aos-duration="700" data-aos="fade-up">
          <h4 className="inner-about-title">
            SMALLEST CARBON FOOTPRINT AND SMALLEST PHYSICAL FOOTPRINT OF ALL
            POWER PLANTS
          </h4>
        </div>

        <div data-aos-duration="700" data-aos="fade-up">
          <div className="about-info-wrap">
            <div className="earth-img">
              <img src={inside} alt="" />
            </div>

            <div className="about-info-content">
              <p className="highlight-text">
                ENERGY196 POWER PLANTS EMIT ABOUT 99 PERCENT LESS CARBON DIOXIDE
                THAN FOSSIL FUEL POWER PLANTS OF SIMILAR SIZE.
              </p>

              <p className="desc-text">
                ENERGY196, Inc. can meet the most stringent clean air standards
                because our common practice is to inject our ENERGY196 fluid
                back into reservoirs to sustain resources and diminish carbon
                dioxide emissions into the atmosphere.
              </p>
            </div>
          </div>
        </div>

        <div className="py-5">
          <p className="med-text">
            ENERGY196 works with global partners and our related accelerator
            program to identify the most promising new ideas throughout the
            world to continue building our portfolio of solutions.
          </p>
        </div>
      </div>

      {/* <Bottom /> */}
    </>
  );
};

export default AboutUs;
