const Team = [
  {
    id: 1,
    name: "Dr. Jay Patel",
    position: "Founder",
    img_path: require("../../assets/img/team/jayy.png"),
    category: "capital",
    linkedIn: "https://www.linkedin.com/in/jay-patel-a64450146/",
  },
  {
    id: 11,
    name: "Manish Mishra",
    position: "International Statergy",
    img_path: require("../../assets/img/team/manish.png"),
    category: "e&p",
    linkedIn: "https://www.linkedin.com/in/manishmishraenergyallied/",
  },
  // {
  //     "id": 2,
  //     "name": "Dr. Andrew Davidoff",
  //     "position": "Founder & CTO",
  //     "img_path": require("../../assets/img/team/Andrew.png"),
  //     "category": "capital"
  // },
  // {
  //     "id": 3,
  //     "name": "Dr. Michael Shoemaker",
  //     "position": "COO",
  //     "img_path": require("../../assets/img/team/Michael.png"),
  //     "category": "capital"
  // },
  // {
  //     "id": 4,
  //     "name": "Darrell Gordon",
  //     "position": "CFO",
  //     "img_path": require("../../assets/img/team/Darrell.png"),
  //     "category": "royalties"
  // },
  // {
  //     "id": 5,
  //     "name": "Mike Fenton",
  //     "position": "Chief Engineer",
  //     "img_path": require("../../assets/img/team/Mike.png"),
  //     "category": "royalties"
  // },
  // {
  //     "id": 6,
  //     "name": "Shane Quinby",
  //     "position": "Compliance Officer",
  //     "img_path": require("../../assets/img/team/Shane.png"),
  //     "category": "royalties"
  // },
  // {
  //   id: 8,
  //   name: "Frank Monachelli",
  //   position: "Chief Financial Officer",
  //   img_path: require("../../assets/img/team/Frank.png"),
  //   category: "innovation",
  //   linkedIn: "https://www.linkedin.com/in/frankmonachelli/",
  // },
  {
    id: 9,
    name: "Trey Storer",
    position: "Head of Business Development",
    img_path: require("../../assets/img/team/treyy.png"),
    category: "e&p",
    linkedIn: "https://www.linkedin.com/in/trey-storer/",
  },
  {
    id: 7,
    name: "Mayur Patel",
    position: "Head Of Operation",
    img_path: require("../../assets/img/team/mayur.png"),
    category: "innovation",
    linkedIn: "https://www.linkedin.com/in/mayur-patel-273746235/",
  },
  {
    id: 10,
    name: "Albert Balderas",
    position: "Business Development Executive",
    img_path: require("../../assets/img/team/albert.png"),
    category: "e&p",
    linkedIn: "https://www.linkedin.com/in/albert-balderas-16b00978/",
  },
  // {
  //   id: 12,
  //   name: "Ankur Trivedi",
  //   position: "Business Development Executive",
  //   img_path: require("../../assets/img/team/ankur.png"),
  //   category: "e&p",
  //   linkedIn: "",
  // },
];

export default Team