import React from "react";
import { Col, Row } from "react-bootstrap";
import inside from '../../assets/img/inside.png'

const Brief = () => {
  return (
    <section className="brief-section">
      <Row className="g-0">
        <Col lg="6">
          <div className="brief-content-wrap">
            <h4 className="brief-text">
              <ul>
                <li>Make the existing energy production cleaner</li>
                <li>Develop new clean production sources</li>
                <li>Drive renewable energy production and growth</li>
                <li>
                  Improve the mobility infrastructure to be more efficient
                </li>
                <li>
                  Support the creation of markets to drive this innovation
                </li>
              </ul>
            </h4>
          </div>
        </Col>

        <Col lg="6">
          <div className="img-wrap d-flex align-items-center justify-content-center">
            <img src={inside} alt="" className="brief-img" />
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default Brief;
