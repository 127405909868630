import React from 'react'

const InnerBanner = props => {
  return (
    <div className='inner-banner'>
      <div data-aos-duration="700" data-aos="fade-up" data-aos-delay="1100" className='w-100'>
        <h2 className="inner-banner-title"> {props.title} </h2>
      </div>
    </div>
  )
}

export default InnerBanner