import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faPhoneFlip } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import logo from '../../../assets/img/logo-icon-light.png'

const Footer = () => {
  return (
    <div className='footer-wrap'>
      <div className='footer-top d-md-flex align-items-center'>
        <a href='/' className='footer-logo'>
          {/* <img src={logo} alt="" /> */}
          <p className='footer text-white fs-4'>ENERGY196</p>
        </a>

        <div className='footer-menu d-md-flex align-items-center'>
          <a href='/' className='footer-link'>
            Home
          </a>
          <a href='/about-us' className='footer-link'>
            About Us
          </a>
          {/* <a href="/team" className="footer-link">
            Teams
          </a> */}
          <a href='/esg' className='footer-link'>
            ESG
          </a>
          <a href='/portfolio-partners' className='footer-link'>
            Portfolio Partners
          </a>
        </div>
      </div>

      <div className='footer-bottom'>
        <div className='d-md-flex align-items-center justify-content-between'>
          <p className='copyright'>© 2024 ENERGY196. All Rights Reserved.</p>

          <div className='social-wrap d-flex align-items-center'>
            {/* <a href="tel:2816059553" className="social-link">
              {" "}
              <FontAwesomeIcon icon={faPhoneFlip} />{" "}
            </a> */}
            {/* <a href="/" className="social-link">
              {" "}
              <FontAwesomeIcon icon={faEnvelope} />{" "}
            </a> */}

            <a href='/terms-of-use' className='footer-link'>
              Terms of use
            </a>

            <a href='/legal-disclaimer' className='footer-link'>
              Legal Disclaimer
            </a>

            <a href='/privacy-policy' className='footer-link'>
              Privacy Policy
            </a>

            <a href='/cookie-policy' className='footer-link'>
              Cookie Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
