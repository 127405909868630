import React from "react";
import InnerBanner from "../innerBanner";
import project from "../../assets/img/energy1.png";
import solar from "../../assets/img/solarEnergy.png";
import hydrogen from "../../assets/img/hydrogen.png";
import storage from "../../assets/img/storage.png";
import electri from "../../assets/img/electri.png";
import carbon from "../../assets/img/carbon.png";
import vehicle from "../../assets/img/vehicle.png";
// import hydro from "../../assets/img/hydro.png";
import thermal from "../../assets/img/thermal.png";
import Loader from "../layout/loader";

const EnergyTransition = () => {
  return (
    <>
      <Loader />
      <InnerBanner title="Energy Transition" />

      <div className="container">
        <div data-aos-duration="700" data-aos="fade-up" className="container">
          <div className="row">
            <div className="col-md-12">
              <br /> <br />
              <p className="energy-para">
                We find opportunities across the globe in energy transition
                products and technologies that will help address the climate
                crisis, including (but not limited to):
              </p>
              <br />
            </div>
            <div className="col-md-3">
              <div className="thumbnail">
                <img src={project} alt="Lights" />
                <div class="caption">
                  <h2>WIND</h2>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="thumbnail">
                <img src={solar} alt="Lights" />
                <div class="caption">
                  <h2>SOLAR</h2>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="thumbnail">
                <img src={hydrogen} alt="Lights" />
                <div class="caption">
                  <h2>HYDROGEN</h2>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="thumbnail">
                <img src={storage} alt="Lights" />
                <div class="caption">
                  <h2>ENERGY STORAGE</h2>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="thumbnail">
                <img src={electri} alt="Lights" />
                <div class="caption">
                  <h2>CHARGING STATIONS</h2>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="thumbnail">
                <img src={carbon} alt="Lights" />
                <div class="caption">
                  <h2>cARBON CAPTURE</h2>
                </div>
              </div>
            </div>
            {/* <div className="col-md-3">
              <div className="thumbnail">
                <img src={hydro} alt="Lights" />
                <div class="caption">
                  <h2>HYDRO</h2>
                </div>
              </div>
            </div> */}
            <div className="col-md-3">
              <div className="thumbnail">
                <img src={vehicle} alt="Lights" />
                <div class="caption">
                  <h2>ELECTRIC VEHICLE</h2>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="thumbnail">
                <img src={thermal} alt="Lights" />
                <div class="caption">
                  <h2>GEOTHERMAL</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bannerWrap">
        <div>
          <p className="investment-theme">Investment Themes</p>
          <div className="row buttonWrapper">
            <div className="col-md-2">
              <button>Energy Transition</button>
            </div>
            <div className="col-md-2">
              <button>Sustainable Transition</button>
            </div>
            <div className="col-md-2">
              <button>Net Zero Real Estate</button>
            </div>
            <div className="col-md-2">
              <button>Circular Economy</button>
            </div>
            <div className="col-md-2">
              <button>Water Solutions</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnergyTransition;
