import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./assets/sass/index.scss";
import {
  EnergyTransition,
  Solution,
  About,
  Swers,
  REHM,
  ARCS,
  Home,
  TeamPage,
  Technology,
  Contact,
  PortfolioPartner,
  ESG,
  Advantages,

  Investor,
  News,
  LeadingProgram,
  ORC,
  FlashPlant,
  BinaryPlant,
  FlareRecoveryTechnology,
  NaturalGasLiquidTechnology,
  IncreasedPipelineCapacity,
  Hydrogen,
} from "./components";
import { TermsOfUse, Legaldisclaimer, CookiePolicy, PrivacyPolicy } from './components/terms'
import Layout from "./components/layout";
import Aos from "aos";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about-us" element={<About />} />
          <Route path="technology" element={<Technology />} />
          {/* <Route path="technology/rehm" element={<REHM />} />
          <Route path="technology/arcs" element={<ARCS />} />
          <Route path="technology/swers" element={<Swers />} /> */}
          {/* <Route path="team" element={<TeamPage />} /> */}
          <Route path="contact" element={<Contact />} />
          <Route path="portfolio-partners" element={<PortfolioPartner />} />
          <Route path="esg" element={<ESG />} />
          <Route path="advantages" element={<Advantages />} />
          <Route path="investor" element={<Investor />} />
          <Route path='terms-of-use' element={<TermsOfUse />} />
          <Route path='legal-disclaimer' element={<Legaldisclaimer />} />
          <Route path='privacy-policy' element={<PrivacyPolicy />} />
          <Route path='cookie-policy' element={<CookiePolicy />} />
          {/* <Route path="news" element={<News />} /> */}
          <Route path="leading-program" element={<LeadingProgram />} />
          <Route path="technology/orc" element={<ORC />} />
          <Route path="technology/flash-steam" element={<FlashPlant />} />
          <Route
            path="technology/scicat-technology"
            element={<BinaryPlant />}
          />
          <Route
            path="technology/flare-capture-and-monetization"
            element={<FlareRecoveryTechnology />}
          />
          <Route
            path="technology/natural-gas-liquids-recovery"
            element={<NaturalGasLiquidTechnology />}
          />
          <Route
            path="technology/increased-pipeline-capacity"
            element={<IncreasedPipelineCapacity />}
          />
          <Route path="technology/hydrogen" element={<Hydrogen />} />
          <Route path="technology/transition" element={<EnergyTransition />} />
          <Route path="technology/solution" element={<Solution />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
