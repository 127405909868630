import React from "react";
import video from "../../assets/video/Geothermalcore.mp4";
import banner from "../../assets/img/banner.png";
import logo from "../../assets/img/logo-lg.png";

const Banner = () => {
  return (
    <div className="banner-wrap">
      <div data-aos-duration="500" data-aos="fade-up" data-aos-delay="1100">
        <img src={banner} className="banner-video" />
      </div>
      <div className="overlay"></div>

      <div className="banner-content">
        <div
          data-aos-duration="500"
          data-aos="fade-up"
          data-aos-delay="1300"
          className="d-flex flex-column align-items-center"
        >
          <h3 className="banner-title text-center">
            Driving Energy Innovation Globally for a Sustainable Future
          </h3>
          <p className="banner-content-text text-white text-center">
            Our mission is to drive energy innovation by supporting a range of
            energy solutions to drive clean, efficient, global renewable energy.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
